@tailwind base;

/* Your own custom base styles */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Your own custom component styles */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */
.banner {
  background-image: url("/src/images/business-team-meeting-boardroom.jpg");
  width: 100%;
  height: calc(100vh - 68px);
  background-position: center center;
  background-size: cover;
  filter: brightness(0.4);
}

svg:not(.social) {
  fill: #eab308;
}
